import { render, staticRenderFns } from "./ExamChecklist.vue?vue&type=template&id=3e49d740&scoped=true&"
import script from "./ExamChecklist.vue?vue&type=script&lang=js&"
export * from "./ExamChecklist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e49d740",
  null
  
)

export default component.exports